
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


import implicitModules from "./-embroider-implicit-modules.js";

  for(const [name, module] of Object.entries(implicitModules)) {
    d(name, function() { return module });
  }



d("life/app", function(){ return i("life/app.ts");});
d("life/config/environment", function(){ return i("life/config/environment.js");});
d("life/router", function(){ return i("life/router.ts");});
d("life/services/display", function(){ return i("life/services/display.ts");});
d("life/services/state", function(){ return i("life/services/state.ts");});
d("life/util/board", function(){ return i("life/util/board.ts");});
d("life/util/cell", function(){ return i("life/util/cell.ts");});
d("life/util/helpers", function(){ return i("life/util/helpers.ts");});
d("life/util/types", function(){ return i("life/util/types.ts");});
d("life/services/ember-primitives/setup", function(){ return i("life/services/ember-primitives/setup.js");});
d("life/services/page-title", function(){ return i("life/services/page-title.js");});
d("life/component-managers/glimmer", function(){ return i("life/component-managers/glimmer.js");});
d("life/container-debug-adapter", function(){ return i("life/container-debug-adapter.js");});
d("life/services/-ensure-registered", function(){ return i("life/services/-ensure-registered.js");});
d("life/templates/application", function(){ return i("life/templates/application.ts");});
d("life/routes/application", function(){ return i("life/routes/application.ts");});
d("life/templates/controls", function(){ return i("life/templates/controls.js");});
d("life/templates/controls/config", function(){ return i("life/templates/controls/config.ts");});
d("life/templates/controls/lifetime", function(){ return i("life/templates/controls/lifetime.ts");});
d("life/templates/controls/size", function(){ return i("life/templates/controls/size.js");});
d("life/templates/header", function(){ return i("life/templates/header.ts");});





if (!runningTests) {
  i("../app").default.create({});
}

