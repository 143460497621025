import { template } from "@ember/template-compiler";
import { ExternalLink } from 'ember-primitives/components/external-link';
export const Header = template(`
  <header>
    <ExternalLink href="https://github.com/NullVoxPopuli/game-of-life">
      GitHub 🡕
    </ExternalLink>
  </header>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
