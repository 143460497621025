import { template } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
export const ExternalLink: TOC<{
    Element: HTMLAnchorElement;
    Blocks: {
        default: [];
    };
}> = template(`
  <a target="_blank" rel="noreferrer noopener" href="##missing##" ...attributes>
    {{yield}}
  </a>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ExternalLink;
