import { template } from "@ember/template-compiler";
import { Lifetime } from './controls/lifetime';
import { Config } from './controls/config';
export const Controls = template(`
  <Lifetime />
  <Config />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
